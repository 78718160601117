import { coeffect, registerEventHandler, effects } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { addDays, dateAsISOString } from 'utils/dates';
import {
  CRITERIA_DEFINED_BY_SUGGESTER,
  CRITERIA_DEFINED_BY_SUGGESTER_WITH_FF_DISABLED,
  IS_NEW_CRITERION,
  LISTING_TYPE_CRITERION,
} from 'components/UnifiedCampaignForm/constants';
import registerCriteriaPriceEvents from './SegmentationCriteria/PriceCriterion/events';

function calculateStartDate(
  isSpecificTargeting,
  currentTimeInMillis,
  segmentation
) {
  const daysToAdd = shouldAddThreeDaysToStartDate(
    isSpecificTargeting,
    segmentation
  )
    ? 3
    : 1;
  const defaultStartDate = dateAsISOString(
    addDays(currentTimeInMillis, daysToAdd)
  );
  return defaultStartDate;
}

function shouldAddThreeDaysToStartDate(isSpecificTargeting, segmentation) {
  return isSpecificTargeting && Object.entries(segmentation).length !== 0;
}

function getDefaultValueByField(field) {
  if (field === LISTING_TYPE_CRITERION || field === IS_NEW_CRITERION) {
    return ['1'];
  }
  if (
    CRITERIA_DEFINED_BY_SUGGESTER.includes(field) ||
    CRITERIA_DEFINED_BY_SUGGESTER_WITH_FF_DISABLED.includes(field)
  ) {
    return [];
  }
  return [''];
}

function createCriteria(field) {
  if (field === 'price') {
    return {
      operation: '<>',
      from: '',
      to: '',
    };
  }
  return {
    operation: '=',
    value: getDefaultValueByField(field),
  };
}

export default function registerEvents() {
  registerEventHandler(
    'SET_SPECIFIC_TARGETING_OPTION',
    function setSpecificTargetingOption(
      { state: { isNew, segmentation }, currentDateTime: currentTimeInMillis },
      { isSpecificTargeting }
    ) {
      if (!isNew) {
        return {
          ...state.set({
            isSpecificTargeting,
            showSegmentationStartDateMessage: false,
          }),
        };
      }

      return {
        ...state.set({
          isSpecificTargeting,
          showSegmentationStartDateMessage: shouldAddThreeDaysToStartDate(
            isSpecificTargeting,
            segmentation
          ),
          'formData.startDate': calculateStartDate(
            isSpecificTargeting,
            currentTimeInMillis,
            segmentation
          ),
        }),
      };
    },
    [
      state.get({
        isNew: 'isNew',
        segmentation: 'formData.segmentation',
      }),
      coeffect('currentDateTime'),
    ]
  );

  registerEventHandler(
    'ADD_TARGETING_CRITERIA',
    function addTargetingCriteria(
      { state: { formData, isNew }, currentDateTime: currentTimeInMillis },
      { field }
    ) {
      const { segmentation } = formData;
      const criteria = createCriteria(field);
      const newSegmentation = {
        ...segmentation,
        [field]: criteria,
      };
      const newFormData = {
        ...formData,
        segmentation: newSegmentation,
      };

      if (!isNew) {
        return {
          ...state.set({
            formData: newFormData,
          }),
          ...effects.dispatch(
            'REQUEST_SEGMENTATION_MATCHING_ADS',
            newSegmentation
          ),
        };
      }

      const startDate = dateAsISOString(addDays(currentTimeInMillis, 1));
      const showSegmentationStartDateMessage = false;

      return {
        ...state.set({
          formData: { ...newFormData, startDate },
          showSegmentationStartDateMessage,
        }),
        ...effects.dispatch(
          'REQUEST_SEGMENTATION_MATCHING_ADS',
          newSegmentation
        ),
      };
    },
    [
      state.get({
        formData: 'formData',
        isNew: 'isNew',
        groupId: 'groupId',
      }),
      coeffect('currentDateTime'),
    ]
  );

  registerEventHandler(
    'REMOVE_TARGETING_CRITERIA',
    function removeTargetingCriteria(
      { state: { formData, isNew } },
      { field }
    ) {
      const {
        segmentation: {
          [field]: removedField,
          ...segmentationWithoutRemovedField
        },
      } = formData;

      const newFormData = {
        ...formData,
        segmentation: segmentationWithoutRemovedField,
      };

      if (
        !isNew ||
        Object.entries(segmentationWithoutRemovedField).length !== 0
      ) {
        return {
          ...state.set({
            formData: newFormData,
          }),
          ...effects.dispatch(
            'REQUEST_SEGMENTATION_MATCHING_ADS',
            segmentationWithoutRemovedField
          ),
        };
      }

      return {
        dispatchMany: [
          {
            id: 'CLEAR_TARGETING_CRITERIA',
          },
        ],
      };
    },
    [
      state.get({
        formData: 'formData',
        isNew: 'isNew',
      }),
      coeffect('currentDateTime'),
    ]
  );

  registerEventHandler(
    'CLEAR_TARGETING_CRITERIA',
    function removeLastRemainingTargetingCriteria({
      state: { formData },
      currentDateTime: currentTimeInMillis,
    }) {
      const startDate = calculateStartDate(true, currentTimeInMillis, {});
      return {
        ...state.set({
          formData: {
            ...formData,
            startDate,
            segmentation: {},
          },
          showSegmentationStartDateMessage: false,
        }),
        ...effects.dispatch('REQUEST_SEGMENTATION_MATCHING_ADS', {}),
      };
    },
    [
      state.get({
        formData: 'formData',
        isNew: 'isNew',
      }),
      coeffect('currentDateTime'),
    ]
  );

  registerEventHandler(
    'CHANGE_TARGETING_CRITERIA_OPERATION',
    function changeTargetingCriteriaOperation(
      { state: { formData } },
      { field, operation }
    ) {
      const { segmentation } = formData;

      const newFormData = {
        ...formData,
        segmentation: {
          ...segmentation,
          [field]: { ...segmentation[field], operation },
        },
      };

      return {
        ...state.set({
          formData: newFormData,
        }),
        ...effects.dispatch(
          'REQUEST_SEGMENTATION_MATCHING_ADS',
          newFormData.segmentation
        ),
      };
    },
    [
      state.get({
        formData: 'formData',
      }),
    ]
  );

  registerEventHandler(
    'CHANGE_TARGETING_CRITERIA_VALUE',
    function changeTargetingCriteriaValue(
      { state: { formData } },
      { field, value }
    ) {
      const { segmentation } = formData;
      const newValue = Array.isArray(value) ? value : [value];
      const newFormData = {
        ...formData,
        segmentation: {
          ...segmentation,
          [field]: {
            ...segmentation[field],
            value: newValue,
          },
        },
      };

      return {
        ...state.set({
          formData: newFormData,
        }),
        ...effects.dispatch(
          'REQUEST_SEGMENTATION_MATCHING_ADS',
          newFormData.segmentation
        ),
      };
    },
    [
      state.get({
        formData: 'formData',
      }),
    ]
  );

  registerEventHandler(
    'CHANGE_REGION_VALUE',
    function changeRegionValue({ state: { groupId } }, { field, value }) {
      return {
        ...state.set({
          isRegionSuggesterLoading: true,
        }),
        ...http.post({
          url: '/index.php/cod.content_field_values_suggester?ajaxCall=1',
          body: {
            groupId,
            field,
            search: value,
          },
          config: { contentType: 'application/x-www-form-urlencoded' },
          successEvent: ['REGION_SUGGESTER_OPTION_REQUEST_SENT'],
        }),
      };
    },
    [
      state.get({
        groupId: 'groupId',
      }),
    ]
  );

  registerEventHandler(
    'REGION_SUGGESTER_OPTION_REQUEST_SENT',
    function regionSuggesterOptionRequestSent(_, payload) {
      const suggestions = payload[0] ? payload[0] : [];

      return {
        ...state.set({
          isRegionSuggesterLoading: false,
          regionSuggesterOptions: suggestions,
        }),
      };
    }
  );

  registerEventHandler(
    'CHANGE_CITY_VALUE',
    function changeCityValue({ state: { groupId } }, { field, value }) {
      return {
        ...state.set({
          isCitySuggesterLoading: true,
        }),
        ...http.post({
          url: '/index.php/cod.content_field_values_suggester?ajaxCall=1',
          body: {
            groupId,
            field,
            search: value,
          },
          config: { contentType: 'application/x-www-form-urlencoded' },
          successEvent: ['CITY_SUGGESTER_OPTION_REQUEST_SENT'],
        }),
      };
    },
    [
      state.get({
        groupId: 'groupId',
      }),
    ]
  );

  registerEventHandler(
    'CITY_SUGGESTER_OPTION_REQUEST_SENT',
    function citySuggesterOptionRequestSent(_, payload) {
      const suggestions = payload[0] ? payload[0] : [];

      return {
        ...state.set({
          isCitySuggesterLoading: false,
          citySuggesterOptions: suggestions,
        }),
      };
    }
  );

  registerEventHandler(
    'CHANGE_CAR_MAKE_VALUE',
    function changeCarMakeValue({ state: { groupId } }, { field, value }) {
      return {
        ...state.set({
          isCarMakeSuggesterLoading: true,
        }),
        ...http.post({
          url: '/index.php/cod.content_field_values_suggester?ajaxCall=1',
          body: {
            groupId,
            field,
            search: value,
          },
          config: { contentType: 'application/x-www-form-urlencoded' },
          successEvent: ['CAR_MAKE_SUGGESTER_OPTION_REQUEST_SENT'],
        }),
      };
    },
    [
      state.get({
        groupId: 'groupId',
      }),
    ]
  );

  registerEventHandler(
    'CAR_MAKE_SUGGESTER_OPTION_REQUEST_SENT',
    function carMakeSuggesterOptionRequestSent(_, payload) {
      const suggestions = payload[0] ? payload[0] : [];

      return {
        ...state.set({
          isCarMakeSuggesterLoading: false,
          carMakeSuggesterOptions: suggestions,
        }),
      };
    }
  );

  registerEventHandler(
    'CHANGE_CITY_AREA_VALUE',
    function changeCityAreaValue(_, { field, value }) {
      return {
        ...state.set({
          isCityAreaSuggesterLoading: true,
        }),
        ...http.post({
          url: '/index.php/cod.content_field_values_suggester?ajaxCall=1',
          body: {
            field,
            search: value,
          },
          config: { contentType: 'application/x-www-form-urlencoded' },
          successEvent: ['CITY_AREA_SUGGESTER_OPTION_REQUEST_SENT'],
        }),
      };
    }
  );

  registerEventHandler(
    'CITY_AREA_SUGGESTER_OPTION_REQUEST_SENT',
    function cityAreaSuggesterOptionRequestSent(_, payload) {
      const suggestions = payload[0] ? payload[0] : [];

      return {
        ...state.set({
          isCityAreaSuggesterLoading: false,
          cityAreaSuggesterOptions: suggestions,
        }),
      };
    }
  );

  registerEventHandler(
    'CHANGE_AGENCY_VALUE',
    function changeAgencyValue(_, { field, value }) {
      return {
        ...state.set({
          isAgencySuggesterLoading: true,
        }),
        ...http.post({
          url: '/index.php/cod.content_field_values_suggester?ajaxCall=1',
          body: {
            field,
            search: value,
          },
          config: { contentType: 'application/x-www-form-urlencoded' },
          successEvent: ['AGENCY_SUGGESTER_OPTION_REQUEST_SENT'],
        }),
      };
    }
  );

  registerEventHandler(
    'AGENCY_SUGGESTER_OPTION_REQUEST_SENT',
    function agencySuggesterOptionRequestSent(_, payload) {
      const suggestions = payload[0] ? payload[0] : [];

      return {
        ...state.set({
          isAgencySuggesterLoading: false,
          agencySuggesterOptions: suggestions,
        }),
      };
    }
  );

  registerEventHandler(
    'CHANGE_SEGMENTATION_TEXT_VALUE',
    function changeSegmentationTextValue(_, { field, value }) {
      return {
        ...state.set({
          isSegmentationTextSuggesterLoading: true,
        }),
        ...http.post({
          url: '/index.php/cod.content_field_values_suggester?ajaxCall=1',
          body: {
            field,
            search: value,
          },
          config: { contentType: 'application/x-www-form-urlencoded' },
          successEvent: ['SEGMENTATION_TEXT_SUGGESTER_OPTION_REQUEST_SENT'],
        }),
      };
    }
  );

  registerEventHandler(
    'SEGMENTATION_TEXT_SUGGESTER_OPTION_REQUEST_SENT',
    function segmentationTextSuggesterOptionRequestSent(_, payload) {
      const suggestions = payload[0] ? payload[0] : [];

      return {
        ...state.set({
          isSegmentationTextSuggesterLoading: false,
          segmentationTextSuggesterOptions: suggestions,
        }),
      };
    }
  );

  registerEventHandler(
    'REQUEST_SEGMENTATION_MATCHING_ADS',
    function requestSegmentationMatchingAds(_, payload) {
      return {
        ...http.post({
          url: '/index.php/cod.campaigns_ads_matching_segmentation_criterias',
          body: payload,
          successEvent: ['SEGMENTATION_MATCHING_ADS_SUCCESS'],
          errorEvent: ['SEGMENTATION_MATCHING_ADS_FAILED'],
        }),
      };
    }
  );

  registerEventHandler(
    'SEGMENTATION_MATCHING_ADS_SUCCESS',
    function segmentationMatchingAdsSuccess(
      _,
      [
        {
          numTrovitMatchingAds,
          numMitulaMatchingAds,
          numNestoriaMatchingAds,
          numNuroaMatchingAds,
        },
      ]
    ) {
      return {
        ...state.set({
          matchingAds: {
            numTrovitMatchingAds,
            numMitulaMatchingAds,
            numNestoriaMatchingAds,
            numNuroaMatchingAds,
          },
        }),
      };
    }
  );

  registerEventHandler(
    'SEGMENTATION_MATCHING_ADS_FAILED',
    function segmentationMatchingAdsSuccess() {}
  );

  registerCriteriaPriceEvents();
}

export default {
  global: {
    '404_message_thribee': 'Falta algo',
    '404_message_thribee_cta': 'Leve-me à página inicial',
    '404_message_thribee_description':
      'Esta página já não está online ou talvez a ligação estivesse incorreta',
    accept_cookie_policy_accept: 'Permitir cookies',
    accept_cookie_policy_decline: 'Não permitir cookies',
    accept_cookie_policy_intro:
      'Utilizamos cookies próprios para melhorar a navegação e analisar o uso do site. Ao clicar em "Permitir cookies", você aceita seu uso.',
    accept_cookie_policy_link: 'Saiba mais sobre a nossa Política de Cookies',
    add_dimension_insights_explorer: 'Adicionar dimensão',
    audience_dashboard_competitors_subtitle:
      'Compare source data with other competitors',
    audience_dashboard_source_subtitle:
      'Descubra insights mais profundos para seu conteúdo e audiência',
    audience_dashboard_source_subtitle_for_mitula:
      'Descubra insights mais profundos para seu conteúdo no Mitula Group.',
    audiences_explanation:
      'Using Audience segmentation you can show your ads to a specific audience according to their interests, characteristics, and behaviors',
    average_cpa: 'Avg. CPA',
    billing_contact_email: 'Billing contact email',
    billing_data_add_new_credit_card: 'Novo cartão de crédito',
    billing_data_switch_from_bank_transfer_to_credit_card:
      'A configuração do pagamento por cartão de crédito substitui o tipo de pagamento por transferência bancária em sua conta e futuras faturas serão cobradas neste cartão de crédito.',
    budget_spend_form: 'Definir um orçamento',
    buy_optimize_traffic_in_trovit_mitula:
      'Compre tráfego, otimize e gerencie suas campanhas no Trovit, Mitula, Nestoria e Nuroa',
    campaign_activate_confirm_text:
      'Está seguro que deseja ativar esta campanha?',
    campaign_activate_confirm_title: 'Ativar campanha?',
    campaign_add_criteria_tooltip:
      'Selecione que critérios deseja usar para segmentar seus anúncios. Pode usar mais de um filtro para selecionar seus critérios.',
    campaign_budget_tooltip:
      'Que orçamento deseja estabelecer para esta campanha? Tenha em conta que quando termine seu orçamento, seus anúncios já não sairão nos espaços promocionais e de destaque do Trovit',
    campaign_criteria: 'Critérios de Segmentação',
    campaign_criteria_value: 'Value',
    campaign_custom_cpc_for_mobile:
      'Coloque um CPC específico para o tráfego mobile',
    campaign_delete_confirm_text:
      'Está seguro que deseja eliminar esta campanha?',
    campaign_delete_confirm_title: 'Eliminar campanha?',
    campaign_form_descriptions_budget_monthly:
      'Este é um orçamento mensal, o que significa que estará disponível para o mês corrente. Quando um novo mês começar, ele será redefinido para o valor total.',
    campaign_form_descriptions_cpc:
      'Esse é o custo por clique (CPC), o preço que você pagará por cada clique recebido nessa campanha, até que o orçamento definido por você seja gasto.',
    campaign_form_descriptions_date:
      'Sua campanha começará imediatamente e continuará em exibição até que seja pausada ou cancelada.',
    campaign_form_descriptions_tracking_code:
      'Os parâmetros de acompanhamento de tráfego ajudam você a filtrar facilmente a informação proveniente dessa campanha em seu software de análise preferido.',
    campaign_form_leads_integration: 'Integração',
    campaign_form_leads_note:
      'Você também pode descarregar os leads manualmente usando os botões "Exportar leads" em múltiplos painéis do Thribee',
    campaign_form_receive_leads: 'Recebendo leads',
    campaign_form_webhook_follow_steps_1: 'Siga',
    campaign_form_webhook_follow_steps_2: 'esses passos',
    campaign_form_webhook_follow_steps_3:
      'para colocar o webhook em funcionamento.',
    campaign_form_webhook_status: 'Estado do webhook',
    campaign_form_webhook_status_disabled: 'Desabilitado',
    campaign_form_webhook_status_disabled_description:
      'Webhooks permitem que você receba notificações em tempo real sempre que um lead for gerado.',
    campaign_form_webhook_status_enabled: 'Habilitado',
    campaign_form_webhook_status_enabled_description:
      'Você receberá notificações em tempo real sempre que um lead for gerado.',
    campaign_name_label: 'Nome da sua campanha',
    campaign_pause_confirm_text: 'Está seguro que deseja pausar esta campanha?',
    campaign_pause_confirm_title: 'Pausar campanha?',
    campaign_same_cpc_for_mobile: 'Usar o mesmo CPC para o tráfego mobile',
    campaign_select_criteria: 'Selecionar critérios',
    campaign_to_activate: 'Ativar campanha',
    campaign_to_delete: 'Eliminar campanha',
    campaign_to_pause: 'Pausar campanha',
    campaign_traffic_delivery_tooltip:
      'A entrega estável garante que o tráfego será distribuído para consumir o seu orçamento durante todo o mês. A entrega acelerada envia o tráfego o mais rápido possível, para que o seu orçamento possa ser alcançado antes do final do mês.',
    campaign_type_tooltip:
      'Cada tipo de campanha está pensado para um propósito específico. Premium envia mais tráfego para seu site, a segmentação oferece tráfego qualificado para obter um CPA competitivo, e Display está desenhado para promover a sua marca no Trovit.',
    compare_with_site_data: 'Compare with site data',
    conversion_tracking_modal_description_1:
      'Para criar campanhas de Maximize Conversions, você precisa instalar o tracker de conversões no seu website.',
    conversion_tracking_modal_description_2:
      'Esta ferramenta nos permite medir as métricas das suas campanhas no Thribee para que possamos otimizar sua performance.',
    conversion_tracking_modal_title: 'Instale o tracker de conversões',
    cookie_policy: 'Política de Cookies',
    cpc_mobile_thribee: 'Mobile CPC',
    create_campaign_bidding_cpa_label: 'CPA Objetivo',
    create_campaign_bidding_cpa_warning_title: 'Atenção',
    create_campaign_bidding_cpc_label: 'CPC Manual',
    create_campaign_bidding_recommendation: 'Budget mínimo:',
    create_campaign_bidding_settings_title: 'Configurações de lance',
    create_campaign_bidding_strategy_title: 'Estratégia de lances',
    create_campaign_btn: 'Crie sua campanha',
    create_campaign_choose_objective_clicks_explanation:
      'Pague por clicks nos seus anúncios. Segmente conteúdo e envie tráfego para o seu site.',
    create_campaign_choose_objective_lead_explanation:
      'Obtenha leads diretamente em sua plataforma. Maximize os leads que você receba ao pagar por visitas nos sites da Thribee.',
    create_campaign_choose_objective_maximize_conversions_explanation:
      'Machine learning e inteligência artificial maximizam a quantidade de conversões geradas no seu site.',
    create_campaign_choose_objective_sub_title:
      'Maximize a eficácia da campanha dando prioridade ao seu objetivo principal.',
    create_campaign_choose_objective_sub_title_link:
      'Aprenda mais sobre os objetivos de campanhas no Thribee.',
    create_campaign_choose_objective_title:
      'Escolha seu objetivo para esta campaña',
    create_campaign_delivery_explanation:
      'Selecione como você gostaria de investir seu budget',
    create_campaign_delivery_explanation_accelerated:
      'Accelerated: gaste seu orçamento mais rapidamente. Isso pode fazer com que seu orçamento acabe logo.',
    create_campaign_delivery_explanation_standard:
      'Estável: gaste seu orçamento uniformemente ao longo do tempo.',
    create_campaign_objective_clicks_label: 'Maximize Clicks',
    create_campaign_objective_maximize_conversions_label:
      'Maximize Conversions',
    create_campaign_segmentation_audience_geo_label: 'Localização do usuário',
    create_campaign_segmentation_audience_label: 'Audiência',
    create_campaign_segmentation_content_all_label: 'Todo o conteúdo',
    create_campaign_segmentation_content_explanation:
      'Você pode criar uma campanha para todo o seu conteúdo ou para um conjunto específico de Ads. Se houver mais de uma campanha no momento para o mesmo conteúdo, o conteúdo será anunciado na campanha com o maior CPC.',
    create_campaign_segmentation_content_label: 'Conteúdo',
    create_campaign_segmentation_content_specific_label: 'Conteúdo específico',
    create_campaign_segmentation_content_title: 'Segmente o seu conteúdo',
    create_campaign_start_end_date_card_title: 'Data de início e fim',
    create_campaign_tracking_card_title: 'Tracking',
    criteria_city_title: 'Cidade',
    criteria_country_title: 'País',
    criteria_operation_equals: 'é',
    criteria_operation_not_equals: 'não é',
    criteria_region_title: 'Estado',
    criteria_type_title: 'Tipo de anúncios',
    current_avg_cpc: 'CPC médio',
    current_cr: 'Taxa de conversão',
    defaulter_partner_disclaimer: 'Devido à faturas não pagas em atraso,',
    defaulter_partner_disclaimer_bold:
      'sua conta foi bloqueada e todas as suas campanhas foram interrompidas',
    delivery_ok: 'Entrega OK',
    disclaimer_thribee_popup: 'Olá!',
    disclaimer_thribee_popup_description:
      'Este recurso está disponível apenas para o Trovit, mas não por muito tempo. Nossas outras marcas estão chegando em breve!',
    disclaimer_thribee_popup_ok: 'OK',
    edit_campaign_switch_cpa_warn_back: 'Go back',
    error_message_contact_us_form_partners: 'Por favor, introduza uma mensagem',
    filter: 'Filtros',
    forgot_password: 'Forgot Password',
    forgotten_password_login_thribee:
      'Para recuperar sua senha, digite seu email que usa para acessar Thribee',
    g_accelerated: 'Acelerada',
    g_active: 'Ativas',
    g_address: 'Endereço',
    g_advanced_options: 'Configuração avançada',
    g_all: 'Todas',
    g_budget: 'Investimento',
    g_cancel: 'Cancelar',
    g_city: 'Cidade',
    g_clicks: 'Clicks',
    g_close: 'Cancelar',
    g_company_name: 'Nome da empresa',
    g_continue: 'Continuar',
    g_conversion_rate: 'Taxa de conversão',
    g_conversions: 'Conversões',
    g_copy: 'Copy',
    g_cost: 'Custo',
    g_country: 'País',
    g_cpa: 'CPA',
    g_cpc: 'CPC',
    g_daily: 'Diário',
    g_day: 'dia',
    g_deleted: 'Eliminadas',
    g_delivery: 'Delivery',
    g_download: 'Descarregar',
    g_edit: 'Editar',
    g_end_date: 'Data de finalização',
    g_free_clicks: 'Free clicks',
    g_from_price: 'Desde',
    g_month: 'mês',
    g_monthly: 'Mensal',
    g_password: 'Senha',
    g_paused: 'Pausadas',
    g_post_code: 'CEP',
    g_premium: 'Premium',
    g_region: 'Estado',
    g_save: 'Salvar',
    g_scheduled: 'Planificadas',
    g_segmentation: 'Segmentation',
    g_standard: 'Estável',
    g_start_date: 'Data de início',
    g_subtotal: 'Subtotal',
    g_telephone: 'Telefone',
    g_to_activate: 'Ativar',
    g_to_delete: 'Eliminar',
    g_to_pause: 'Pausar',
    g_to_price: 'Até',
    g_traffic_overview: 'Ver dados de tráfego',
    g_vat_number: 'CNPJ',
    generic_conversions: 'Conversão',
    header_upgrade_cta: 'ATUALIZAR',
    homepage_button_contactus: 'Nos contactar',
    homepage_button_demorequest: 'Solicite uma demonstração',
    homepage_button_login: 'Fazer Login',
    homepage_form_company: 'Nome da empresa',
    homepage_form_country: 'País',
    homepage_form_email: 'E-mail',
    homepage_form_name: 'Nome',
    homepage_form_subtitle:
      'Comece a publicar nos<strong> principais agregadores e expandir seu alcance</strong> com o Thribee',
    homepage_form_surname: 'Sobrenome',
    homepage_form_terms_and_conditions:
      'Aceito os Termos e Condições e a Política de Privacidade.',
    homepage_form_title: 'Nos contactar',
    homepage_section1_subtitle1:
      'Trabalhe com Thribee, a solução de marketing com todos os principais agregadores',
    homepage_section1_subtitle2:
      'Publique seu conteúdo, compre tráfego, gerencie e otimize suas campanhas em:',
    homepage_section1_title:
      '<span>Quer anuciar com a gente</span>? Expanda seu alcance com nosso público qualificado',
    homepage_section2_firstbox_explanation:
      'Publique seu conteúdo e comece a receber tráfego, <strong>de acordo com suas necessidades</strong> e obtenha o máximo de nosso público.',
    homepage_section2_firstbox_title: 'Aumente seu alcance',
    homepage_section2_secondbox_explanation:
      'Você atingirá um <strong>público-alvo com alto interesse no setor</strong>. Garanta sua conversão e faça valer cada centavo.',
    homepage_section2_secondbox_title: 'Audiência qualificada',
    homepage_section2_thirdbox_explanation:
      'Com presença em mais de 63 países, e mais de 200M de anúncios indexados, não só conhecemos o mercado. Nós somos o mercado.',
    homepage_section2_thirdbox_title: 'Impacto global, conhecimento local',
    homepage_section2_title:
      'A solução de marketing para portais de classificados',
    homepage_section4_firstbox_explanation:
      'Usamos diferentes opções para indexar seu conteúdo. Fale conosco para encontrar a melhor solução para você e garantir que seu conteúdo esteja online em apenas algumas horas.',
    homepage_section4_firstbox_title: 'Fácil configuração',
    homepage_section4_secondbox_explanation:
      'Defina sua estratégia e escolha a opção que funciona melhor para você: campanhas CPC, CPA ou CPM. Conte com nossos especialistas que ajudam você a decidir a melhor estratégia para atingir seus objetivos.',
    homepage_section4_secondbox_title: 'Alcance seus objetivos',
    homepage_section4_thirdbox_explanation:
      'Defina um orçamento, um lance e gerencie em tempo real seus gastos e resultados diários. Faça alterações a qualquer momento para garantir que você aproveite ao máximo seu investimento.',
    homepage_section4_thirdbox_title: 'Você tem controle',
    homepage_section4_title:
      'Uma solução <span>feita para atender às suas necessidades</span>',
    homepage_section5_subtitle: 'Confira este vídeo!',
    homepage_section5_title: 'Quer saber mais?',
    homepage_section6_title:
      '<span>Empresas de todo o mundo</span> confiam em nós há mais de 10 anos.',
    homepage_section7_countries: 'Países',
    homepage_section7_listings: 'Anúncios',
    homepage_section7_partners: 'Parceiros',
    homepage_section7_testimonial1_name:
      'Silvio Pagliani <br /> <strong>CEO Immobiliare</strong>',
    homepage_section7_testimonial1_text:
      'Temos uma parceria lucrativa e duradoura com a Lifull Connect ao longo dos anos',
    homepage_section7_testimonial2_name:
      'Denis Maeda <br /> <strong>Quintoandar Group</strong>',
    homepage_section7_testimonial2_text:
      'A Lifull Connect é um parceiro importante para a nossa estratégia de aquisição de leads, e o atendimento que recebemos deles é um dos motivos pelo qual os resultados são tão expressivos.',
    homepage_section7_testimonial3_name:
      'Agustina Izarra Piquet <br /> <strong>Marketing Digital Navent Group</strong>',
    homepage_section7_testimonial3_text:
      'Trabalhamos com a Lifull Connect há anos. Eles são parceiros importantes para nossos resultados de conversão.',
    homepage_section7_title: '<span>Líderes mundiais</span> em agregação',
    homepage_section7_verticals: 'Verticais',
    homepage_section8_title: 'Vamos levá-lo ao próximo nível de classificados!',
    invoice_overdue: 'Vencida sem pagar',
    invoice_paid: 'Cobrada',
    invoice_pending: 'Pendente de pagamento',
    invoices_list_header_amends: 'Creditado',
    invoices_list_header_amount: 'Valor',
    invoices_list_header_due: 'vencimento',
    invoices_list_header_issuer: 'Emitente',
    invoices_list_header_nr: 'Fatura nº',
    invoices_list_header_period: 'Periodo',
    invoices_list_header_status: 'Estado',
    invoices_list_header_type: 'Tipo de documento',
    invoices_list_title: 'Faturas',
    invoices_list_value_credit_note: 'Nota de crédito',
    invoices_list_value_invoice: 'Fatura',
    invoices_load_more_invoices_btn: 'Carregar mais faturas',
    invoices_no_available_for_issuer_text:
      'Aqui você só encontrará as notas fiscais emitidas por "Trovit Search SLU" e "Mitula Classified SLU". Se você precisar vizualizar ou baixar faturas emitidas por uma empresa diferente, entre em contato com seu Account Manager.',
    invoices_no_available_for_issuer_title:
      'Algumas faturas não estão visíveis neste modo de visualização',
    invoices_tooltip:
      'Você tem alguma dúvida sobre suas faturas? Verifique todas as informações em nossa Central de Ajuda',
    js_CampaignGenericException_CpcLowerThanMinimumConfigurationException:
      'O CPC escolhido ([campaignCpc]) é menor que o mínimo permitido ([configCpc]). Por favor, assegure-se que o CPC escolhido seja igual ou maior que o mínimo.',
    js_CampaignGenericException_DailyBudgetLowerThanMinimumConfigurationException:
      'O investimento diário escolhido ([campaignBudget]) é menor que o mínimo permitido ([configBudget]). Por favor, assegure-se que o investimento escolhido seja igual ou maior que o mínimo.',
    js_CampaignGenericException_EmptyDisplayTitleException:
      'O campo "título" para a campanha Display não pode estar vazio',
    js_CampaignGenericException_InvalidDeliveryMethodException:
      'O método de entrega deve ser Estável ou Acelerado',
    js_CampaignGenericException_MonthlyBudgetLowerThanMinimumConfigurationException:
      'O investimento mensal escolhido ([campaignBudget]) é menor que o mínimo permitido ([configBudget]). Por favor, assegure-se que o investimento escolhido seja igual ou maior que o mínimo.',
    js_CampaignGenericException_MonthlyBudgetLowerThanSpentBudgetException:
      'Não é permitido escolher um investimento menor que o já consumido este mês. Já foi consumido [campaignBudget] e o investimento que está tentando colocar é [configBudget]. Por favor, assegure-se que o investimento seja maior que o já consumido.',
    js_add: 'Inserir',
    js_add_filter: 'Adicionar Filtros',
    js_advanced_filters: 'Filtros avançados',
    js_all: 'Todos',
    js_apply_button: 'Procurar',
    js_apply_daterange: 'Apply',
    js_aside_menu_tour_language_step_text:
      'Escolha que idioma deseja para seu Trovit Partners',
    js_aside_menu_tour_language_step_title: 'Escolha um idioma',
    js_aside_menu_tour_menu_items_step_text:
      'Estas são todas as seções que pode utilizar para ver e gestionar seu tráfego e ferramentas úteis para melhorar sua performance',
    js_aside_menu_tour_menu_items_step_title: 'Navegação',
    js_aside_menu_tour_notifications_step_text:
      'Informaremos eventos importantes aqui, abaixo do botão de notificações',
    js_aside_menu_tour_notifications_step_title: 'Notificações',
    js_aside_menu_tour_settings_step_text:
      'Aqui encontrará opções adicionais, como configuração de usuários',
    js_aside_menu_tour_settings_step_title: 'Configuração',
    js_aside_menu_tour_sources_step_text:
      'Aqui pode selecionar quais portais deseja gestionar (se tiver mais de um)',
    js_aside_menu_tour_sources_step_title: 'Escolha seu portal',
    js_billing_info_tour_add_new_data_step_text:
      'Preencha estes campos para criar nova conta para faturação',
    js_billing_info_tour_add_new_data_step_title:
      'Adicione novos dados de faturação',
    js_billing_info_tour_click_to_set_step_text:
      'Pode alterar a conta de faturação clicando em outra conta',
    js_billing_info_tour_click_to_set_step_title: 'Altere a conta de faturação',
    js_billing_info_tour_current_data_step_text:
      'Suas contas para faturação aparecerão aqui. Se tiver mais de uma, sempre haverá uma ativa e vinculada ao portal que esta gestionando no momento. Poderá escolher entre uma e outra em qualquer momento.',
    js_billing_info_tour_current_data_step_title: 'Contas de faturação',
    js_blocked_by_sales: 'Blocked by sales',
    js_brand_tour_apps_urls_step_text:
      'E mais, você poderá adicionar links para seus Apps nas Apps Stores para patrocinar instalações de sua App',
    js_brand_tour_apps_urls_step_title: 'Links de seu App',
    js_brand_tour_images_step_text:
      'Suba seus logos aqui para que apareçam em suas campanhas de Display no Trovit',
    js_brand_tour_images_step_title: 'Logos',
    js_brand_tour_title_and_description_step_text:
      'Adicione um slogan e uma descrição para que os usuários do Trovit conheçam melhor o seu site e sua marca',
    js_brand_tour_title_and_description_step_title: 'Eslogan',
    js_campaign_all_criteria:
      'Não segmentar - Incluir todos os anúncios nesta campanha',
    js_campaign_created:
      'Sua campanha foi criada com sucesso! Você pode ver mais detalhes nas configurações de campanhas.',
    js_campaign_save_criteria_required:
      'Pelo menos 1 critério é necessário para criar uma segmentação',
    js_campaign_save_custom_banner_creative_height_does_not_match:
      'A altura da imagem não corresponde ao tamanho do banner selecionado.',
    js_campaign_save_custom_banner_creative_max_size_reached:
      'O arquivo não deve exceder o limite de 300 KB.',
    js_campaign_save_custom_banner_creative_required:
      'Você deve fazer o upload de pelo menos uma imagem para a criatividade',
    js_campaign_save_custom_banner_creative_width_does_not_match:
      'A largura da imagem não corresponde ao tamanho do banner selecionado.',
    js_campaign_save_custom_banner_creative_wrong_file_type:
      'O tipo de arquivo enviado nao é válido. Você deve fazer upload de um arquivo PNG.',
    js_campaign_save_custom_banner_url_required:
      'É necessário introduzir uma URL de destino.',
    js_campaign_tour_budget_step_text:
      '<p> Defina quanto você pretende gastar com esta campanha. Campanhas Premium sempre tem um investimento mensal , enquanto nas campanhas de Display e Segmentação você pode definir um investimento diário . </p><P> Nunca cobraremos mais do que você estabeleceu como investimento, mas tenha em mente que, se o investimento se esgota, vamos parar de enviar-lhe tráfego das seções destacadas . </p>',
    js_campaign_tour_budget_step_title: 'Estabeleça seu investimento',
    js_campaign_tour_cpc_step_text:
      'O custo por click que você define afeta a visibilidade dos seus anúncios nas seções destacadas. Quanto maior é em relação a seus concorrentes , mais seus anúncios aparecem nessas seções.',
    js_campaign_tour_cpc_step_title: 'Estabeleça seu CPC',
    js_campaign_tour_criteria_step_text:
      '<p> Filtre os anúncios que deseja promover usando critérios diferentes . Estabeleça critérios e escolha seus valores. </p><p> Pode escolher mais de um critério e aplicar mais de um valor em alguns casos. Nós recomendamos que você passe o mouse sobre o círculo amarelo ao lado do nome do critério para obter mais informações e saber quais os valores que você pode usar. </p>',
    js_campaign_tour_criteria_step_title: 'Segmente seus anúncios',
    js_campaign_tour_delivery_step_text:
      '<p> A entrega standart garante que o seu orçamento seja consumido de forma uniforme ao longo do mês ( de modo que o tráfego que recebe do Trovit seja estável ) . </p><p> A entrega acelerada tenta trazer o máximo de tráfego possível para seu site com o investimento disponível. </p>',
    js_campaign_tour_delivery_step_title:
      'Escolha seu método de entrega preferido',
    js_campaign_tour_end_date_step_text:
      'Pode pausar ou finalizar sua campanha sempre que quiser, sem ter que definir uma data final, mas você também tem a possibilidade de fixar uma data para a campanha pausar automaticamente.',
    js_campaign_tour_end_date_step_title:
      'Se desejar, estabeleça uma data de finalização',
    js_campaign_tour_start_date_step_text:
      'A campanha pode começar agora, ou pode especificar uma data de início',
    js_campaign_tour_start_date_step_title:
      'Quando deseja que comece a campanha?',
    js_campaigns_manage_tour_edit_inline_step_text:
      'Basta clicar no CPC e / ou investimento para mudar facilmente desde aqui',
    js_campaigns_manage_tour_edit_inline_step_title:
      'Altere seu CPC e investimento comodamente',
    js_campaigns_manage_tour_filters_step_text:
      'Use estes botões para filtrar suas campanhas por "estado"',
    js_campaigns_manage_tour_filters_step_title: 'Filtrar por estado',
    js_campaigns_manage_tour_list_order_step_text:
      'Clique no nome das colunas para classificar suas campanhas por valores.',
    js_campaigns_manage_tour_list_order_step_title: 'Escolha suas campanhas',
    js_campaigns_manage_tour_list_step_text:
      'Aqui vai encontrar todas as suas campanhas com detalhes como investimento,  estado, CPC, etc.',
    js_campaigns_manage_tour_list_step_title: 'Suas campanhas',
    js_campaigns_manage_tour_update_status_step_text:
      'Acesse os detalhes da campanha ou altere o seu estado utilizando esses links',
    js_campaigns_manage_tour_update_status_step_title: 'Outras opções',
    js_cancel_daterange: 'Cancel',
    js_check_it_out: 'Confira',
    js_content_information_analyse_hidden_content_by_multiple_criterias:
      '<a href="[url]">Analyse</a> seu conteúdo oculto por vários critérios',
    js_content_information_suggestion:
      '<a href="[url]"> [numCmps] campanha </a> sugestão para exibir todo o seu conteúdo',
    js_content_information_suggestion_plural:
      '<a href="[url]"> [numCmps] campanha </a> sugestão para exibir todo o seu conteúdo',
    js_cpc_competitive_average: 'CPC médio',
    js_cpc_competitive_competitive: 'CPC competitivo',
    js_cpc_competitive_fair: 'CPC razoável',
    js_cpc_competitive_uncompetitive: 'CPC não competitivo',
    js_criteria_is_new_title: 'Lançamentos',
    js_criteria_type_country_house_rentals: 'Casas de campo para alugar',
    js_criteria_type_for_rent_local: 'Lojas para alugar',
    js_criteria_type_for_sale: 'Imóveis à venda',
    js_criteria_type_for_sale_local: 'Lojas para vender',
    js_criteria_type_land_to_sale: 'Terrenos à venda',
    js_criteria_type_office_for_rent: 'Escritórios para alugar',
    js_criteria_type_office_for_sale: 'Escritórios para vender',
    js_criteria_type_overseas: 'Imóveis no exterior',
    js_criteria_type_parking_for_rent: 'Estacionamento para alugar',
    js_criteria_type_parking_for_sale: 'Estacionamento para vender',
    js_criteria_type_property_to_let: 'Imóveis de aluguel',
    js_criteria_type_rentals: 'Aluguel de temporada',
    js_criteria_type_roommate: 'Compartilhar quartos',
    js_criteria_type_short_term_rentals: 'Aluguel por poucos dias',
    js_criteria_type_title: 'Tipo de anúncios',
    js_criteria_type_transfer_local: 'Sala comercial para vender',
    js_criteria_type_unlisted_foreclosure: 'Imóveis de bancos',
    js_criteria_type_warehouse_for_rent: 'Galpões para alugar',
    js_criteria_type_warehouse_for_sale: 'Galpões à venda',
    js_csv_format_information:
      'Se você estiver usando o Microsoft Excel e parece que o formato de arquivo não está correto, faça o seguinte: 1. Selecione a coluna inteira A 2. No menu "dados" clique "texto em colunas" 3. Certifique-se de "Delimitado"está selecionado e clique em" Next "4. Clique em" Outro "e colocar um"; "(ponto e vírgula sem as aspas) e, em seguida, em "Concluir"',
    js_decimals_char: ',',
    js_duplicate_ads_exposed: 'Anúncios duplicados visíveis',
    js_duplicate_hidden_text: 'Anúncios duplicados não visíveis',
    js_duplicate_warning:
      'Você não tem anúncios duplicados para essa seleção de critérios. Por favor, tente com uma pesquisa mais ampla.',
    js_error_tracking_code_format:
      'O formato do código de seguimento não é válido',
    js_filters_selected: '%d selecionado',
    js_g_date: 'Data',
    js_global_warning_click: 'Corrigir',
    js_invoices_no_results_to_show: 'Não possui faturas',
    js_loading_data: 'Carregando dados...',
    js_none: 'Nenhum',
    js_search: 'Procurar',
    js_select_campaigns: 'Campanha',
    js_select_cities: 'Cidade',
    js_select_competitors: 'Concorrentes',
    js_select_job_positions: 'Categoria de Emprego',
    js_select_make: 'Marca de carros',
    js_select_model: 'Modelo de carros',
    js_select_none: 'Nenhum',
    js_select_regions: 'Distrito',
    js_thousands_char: '.',
    js_tour_close: 'Fechar guia',
    js_tour_done: 'Terminar',
    js_tour_next: 'Seguinte &raquo;',
    js_tour_prev: '&raquo; Anterior',
    js_tour_skip: 'Pular guia',
    js_traffic_overview_tour_detail_step_text:
      'Clique no nome das colunas para classificar as campanhas pelos valores das mesmas colunas',
    js_traffic_overview_tour_detail_step_title: 'Escolha campanhas',
    js_traffic_overview_tour_export_step_text:
      'Pode exportar os dados selecionados através do intervalo de datas em um arquivo CSV',
    js_traffic_overview_tour_export_step_title: 'Exporte dados em CSV',
    js_traffic_overview_tour_filter_step_text:
      'Quais métricas gostaria de ver no gráfico?',
    js_traffic_overview_tour_filter_step_title: 'Selecione métricas',
    js_traffic_overview_tour_graph_zoom_step_text:
      'Para ver em detalhe, selecione um intervalo de dados no gráfico. Click e arraste de um ponto a outro. O gráfico mostrará em detalhe o intervalo escolhido.',
    js_traffic_overview_tour_graph_zoom_step_title: 'Ver em detalhe no gráfico',
    js_traffic_overview_tour_range_step_text:
      'Selecione o intervalo de dados que deseja ver',
    js_traffic_overview_tour_range_step_title: 'Selecione intervalo de dados',
    js_traffic_overview_tour_rows_step_text:
      'Na página, verá todas as campanhas ativadas durante o intervalo de datas escolhido e suas métricas. As campanhas apagadas estão marcadas na página, mas são mostradas como ativas durante o intervalo escolhido.',
    js_traffic_overview_tour_rows_step_title: 'Lista de campanhas',
    js_traffic_range_custom: 'Defina um intervalo de datas',
    js_traffic_range_last_3_months: 'Últimos 3 meses',
    js_traffic_range_last_6_months: 'Últimos 6 meses',
    js_traffic_range_last_month: 'Últimos 30 dias',
    js_traffic_range_last_week: 'Última semana',
    js_traffic_range_last_year: 'Ano atual',
    js_traffic_range_previous_month: 'Mês anterior',
    js_traffic_range_this_month: 'Mês atual',
    js_traffic_range_today: 'Hoje',
    js_traffic_range_yesterday: 'Ontem',
    js_utm_automatic_update_heads_up:
      "Heads up! When changing the campaign's name, the UTM values gets updated automatically with the new name.",
    learn_more_help_center: 'Veja mais',
    lifullconnect_label: 'Lifull Connect',
    login: 'Login',
    login_forgotten_password_email_sent:
      'Enviamos um email para recuperar sua senha',
    login_forgotten_password_title: 'Esqueci minha senha',
    menu_campaigns_new: 'Nova campanha',
    message_conflict_banner_ru_generic:
      'Thribee suspended business activity in Russia on March 1st until further notice.',
    message_conflict_banner_ua_generic:
      'All Thribee campaigns are running free of charge until further notice. Your campaigns remain active and running normally.',
    msg_missing_billing_data:
      'Aviso! Você precisa preencher seus dados de faturação para que possamos ativar sua campanha',
    new_campaign_help_center:
      'É sua primeira campanha? Encontre todas as informações que você precisa em nossa Central de Ajuda.',
    optimize_campaigns_card_device: 'Dispositivo',
    partners_login: 'Acesso ao Partners',
    partners_no_results: 'Nenhum resultado encontrado',
    primary_dimension: 'Dimensão primária',
    privacy_policy: 'Política de Privacidade',
    pwd_dont_match_thribee: 'As senhas não coincidem',
    reset_password_changed: 'Senha alterada',
    reset_password_failed: 'Alteração de senha inválido. Tente novamente',
    reset_password_thribee: 'Restabelecer senha',
    secondary_dimension: 'Dimensão secundária',
    site_data: 'Site data',
    tab_audience_dashboard_competitors: 'Compare with a competitor',
    tandc_accept: 'Aceite e prossiga',
    tandc_cookies_checkbox:
      'Li e concordo com a <a target="_blank" href="[cookiePolicyUrl]"> política de cookies </a>',
    tandc_error:
      'Ocorreu um erro ao processar o seu pedido. Por favor, tente novamente mais tarde.',
    tandc_reject: 'Recusar e sair',
    tandc_terms_checkbox:
      'Eu li e concordo com os <a target="_blank" href="[termsAndConditionsUrl]"> termos e condições </a> e a <a target="_blank" href="[privacyPolicyUrl]"> política de privacidade </a>',
    terms_changed_intro:
      'Atualizamos nossos termos e condições, política de privacidade e política de cookies. Por favor, leia o texto abaixo e aceite-o se concordar.',
    terms_title: 'Condições de utilização',
    thats_what_we_do: 'Isso é o que fazemos.',
    the_lifull_connect_family: 'Um serviço, quatro agregadores',
    the_traffic_adcquisition_tool:
      'O serviço de aquisição de tráfego nos principais agregadores',
    tooltip_upgrade_info:
      'Esta opção está disponível apenas para parceiros da Golden.',
    total_insights_explorer: 'Total',
    tracking_parameters_form_thribee: 'Parâmetros de tracking',
    traffic_all_data: 'Total de tráfego',
    traffic_desktop_data: 'Tráfego Desktop',
    traffic_export_leads_to_csv: 'Exportar leads',
    traffic_export_to_csv: 'Exportar dados a CSV',
    traffic_mobile_data: 'Tráfego Mobile',
    underdelivery: 'Entrega baixa',
    user_criteria_geo_main: 'Geolocalização do usuário',
    view_invoices: 'View invoices',
    we_help_users_and_organisations:
      'Ajudamos os usuários a encontrar o que estão procurando. Ajudamos as empresas a se conectarem com esses usuários.',
    years_of_experience:
      'Com mais de 14 anos de experiência, vivemos e respiramos agregação.',
  },
};
